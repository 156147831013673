// import 'leaflet.fullscreen/Control.FullScreen';
// import 'leaflet.fullscreen/Control.FullScreen.css';
import Toastify from 'toastify-js'

//  Variables
var defautltLat = '53.332402';
var defaultLong = '-7.742939';
var mapInstance;
var zoom = 7;
var markers;
var markerArray = [];
var mapStyle = [
    'https://tile.openstreetmap.org/{z}/{x}/{y}.png', // Default (0)
    'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', // Simple Clean Light (1)
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', // Street Highlight Orange (2)
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', // Street Highlight Grey/Green (3)
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', // Real World Map (4)
    'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', // Google Style
];


window.addEventListener('stations-map', event => {

    stationsMap(event);

});


/*
*
* Stations Map
* 
*/
function stationsMap(event) {

    var clientWidth = document.getElementById('map').clientWidth;
    var defaultWidth;

    if(clientWidth <= 780)
    {
        defaultWidth = 6;
    }else{
        defaultWidth = 7;
    }
    
    if (event.detail[0].stations.length == 0) {

        // Default Map
        mapInstance = L.map('map').setView([defautltLat, defaultLong], defaultWidth);
        mapInstance.attributionControl.setPrefix('HydroCloud');

        L.tileLayer(mapStyle[0], {
            maxZoom: 20,
            minZoom: 1,
        }).addTo(mapInstance);

        L.control.fullscreen().addTo(mapInstance);

        return mapInstance;

    } else {

        markers = event.detail[0].stations;
        
        // Use these to auto zoom in on most recently added station.
        // var lat = event.detail[0].stations[0].station_lat ?? '53.8562559';
        // var lng = event.detail[0].stations[0].station_long ?? '-6.5497304';
        mapInstance = L.map('map').setView([defautltLat, defaultLong], defaultWidth);
        mapInstance.attributionControl.setPrefix('HydroCloud - '+ event.detail[0].stations[0].organisation.organisation);

        // Continue constructing map below.....

    }

    // Set map style
    L.tileLayer(mapStyle[5], {
        maxZoom: 20,
        minZoom: 1,
        subdomains:['mt0','mt1','mt2','mt3']
        // attribution: "HydroCloud",
    }).addTo(mapInstance);


    L.control.fullscreen().addTo(mapInstance);


    // events are fired when entering or exiting fullscreen.
    // mapInstance.on('enterFullscreen', function () {
    // 	console.log('entered fullscreen');
    // });

    // mapInstance.on('exitFullscreen', function () {
    // 	console.log('exited fullscreen');
    // });


    function getPinLocations(object, index) {
        
        var stationIcon = L.icon({
            iconUrl: '/images/icons/pin-'+ object.status +'.png',
            //  shadowUrl: 'leaf-shadow.png',
            // shadowAnchor: [4, 62],  // the same for the shadow
            iconSize: [36, 36], // size of the icon
            // shadowSize: [50, 64], // size of the shadow
            iconAnchor: [16, 34], // point of the icon which will correspond to marker's location
            popupAnchor: [50, -15.9], // point from which the popup should open relative to the iconAnchor
        });
        
        if(object.station_lat && object.station_long)
        {
            markerArray.push(L.marker([object.station_lat, object.station_long], {

                    icon: stationIcon,
                    stationID: object.id,
                    stationName: object.station_name,
                    stationOrganisation: event.detail[0].stations[0].organisation.organisation,

                }).on("click", function (e) {
                    onMarkerClick(e, object);
                })
            );
        }
    }
    markers.forEach(getPinLocations);

    function groupPins(item) {
        L.layerGroup([item])
            .addTo(mapInstance);
    }
    markerArray.forEach(groupPins);


    return mapInstance;
}



/*
*
* Functions
* 
*/



var onMarkerClick = function (e, object) {

    var clientWidth = document.getElementById('map').clientWidth;
    var newwidth = clientWidth - 140;

    L.popup({ maxWidth: newwidth })
        .setLatLng(e.latlng)
        .setContent(popupContent(e, object))
        .openOn(mapInstance);
}


var popupContent = function (e, object)
{
    var data = '';
    var latest = object.latest;
    var channels = object.channels;
    var status;


    // console.log(object);
    

    switch (object.status)
    {
        case 'inactive':
            status = 'bg-red-50';
        break;

        case 'pending':
            status = 'bg-orange-50';
        break;

        case 'active':
            status = 'bg-green-50';
        break;
    
        default:
            break;
    }

    for (let key in latest)
    {
        if(latest[key] != null)
        {
            data += `
            <tr>
                <td class="lg:px-6 lg:py-4 sm:px-2 max-md:px-2 max-sm:px-2 max-md:py-2 max-sm:py-2 text-left whitespace-nowrap">`+ latest[key].channel_name +`</td>
                <td class="lg:px-6 lg:py-4 sm:px-2 max-md:px-2 max-sm:px-2 max-md:py-2 max-sm:py-2 text-center whitespace-nowrap font-bold">`+ latest[key].value +` `+ latest[key].unit +`</td>
                <td class="lg:px-6 lg:py-4 sm:px-2 max-md:px-2 max-sm:px-2 max-md:py-2 max-sm:py-2 text-end whitespace-nowrap">`+ latest[key].storage_time +`</td>
            </tr>`;
        }
    }
    
    var html = `
        <div class="mt-4 flex justify-left items-center col-span-1 text-base font-medium leading-6 whitespace-nowrap focus:outline-none md:col-span-4 lg:col-span-6">
            <img alt="HydroCloud Logo" src="/images/icons/hydro.png" width="18" />
            <span class="ml-2 text-sm font-bold text-black">
                HydroCloud
            </span>
        </div>
        <div class="shadow-sm border rounded-lg overflow-x-auto mt-4 mb-2">
            <table class="w-full table-auto text-sm text-left">

                <thead class="`+ status +` text-gray-600 font-medium border-b">
                    <tr>
                        <th class="lg:px-6 lg:py-4 sm:px-2 max-md:px-2 max-sm:px-2 max-md:py-2 max-sm:py-2" colspan="3">`+ e.sourceTarget.options.stationName +`</th>
                    </tr>
                </thead>

                <tbody id="stationData" class="text-gray-600 divide-y">
                    
                    `+ data +`
                    
                </tbody>
            </table>
        </div>
        <div class="flex gap-3 border p-1 rounded-md shadow-sm text-center justify-center mb-6 bg-gray-50">
            <a class="map_links" href="https://maps.google.com/?q=` + object.station_lat + `,` + object.station_long + `" target="_blank">
                <svg class="h-6 w-6 hover:stroke-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                </svg>
            </a>
            <a class="map_links" href="http://maps.google.com/maps?q=&layer=c&cbll=` + object.station_lat + `,` + object.station_long + `" target="_blank">
                <svg class="h-6 w-6 hover:stroke-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
            </a>
        </div>`;

    return html;
}



